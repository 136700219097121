import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useIntl } from 'react-intl';

import { SpinnerWithWrapper } from '../../../components/common/spinner';
import QuestionItem from '../../../pages/QuestionProfiles/new-question-item';
import QuestionTypeSwitch from '../../QuestionProfiles/question-type-switch';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
    height: 100%;
    animation: ${fadeIn} 1s;

    > div {
        :first-of-type {
            margin: 0 10px;

            > h2 {
                margin-top: 0;
                margin-bottom: 30px;
                text-transform: capitalize;
                font-size: 24px;
            }
        }
    }
`;

const Questions = styled.div`
    display: flex;
    margin: 24px 0;
    flex-direction: column;
    overflow: auto;
`;

const ReferenceType = styled.div`
    display: flex;
    align-items: center;

    > div {
        > p {
            margin: 0;
        }

        :last-of-type {
            display: flex;
            flex-grow: 1;
            align-items: center;
            margin-left: 10px;
        }
    }
`;

const NoQuestionsForType = styled.div`
    text-align: center;
    padding: 2em;
    background-color: ${(props) => props.theme.palette.common.white};
`;

/* eslint-disable react/display-name */
export const QuestionsPreview = ({
    questionPreview,
    questionProfileId,
    getQuestionProfilePreview,
}) => {
    const { formatMessage } = useIntl();
    const { isFetchingProfile = false, profile = {} } = questionPreview;
    const { questions = [], name } = profile;
    const hasProfile = !!Object.keys(profile).length;
    const [referenceType, setReferenceType] = useState('professional');

    useEffect(() => {
        questionProfileId && getQuestionProfilePreview(questionProfileId);
    }, []);

    if (!questionProfileId) return null;

    if (isFetchingProfile && !hasProfile) return <SpinnerWithWrapper />;

    const questionItems = questions
        .filter((q) => {
            const questionReferenceType = q.ispersonal
                ? 'personal'
                : q.iseducational
                ? 'academic'
                : 'professional';

            return referenceType === questionReferenceType;
        })
        .map((question) => <QuestionItem key={question.qid} {...question} />);

    return (
        <Container>
            <div>
                <h2>
                    {formatMessage({ id: 'ReferenceRequest.questionsPreview.title' }, { name })}
                </h2>
                <ReferenceType>
                    <div>
                        <p>
                            {formatMessage({
                                id: 'ReferenceRequest.questionsPreview.referenceType',
                            })}
                        </p>
                    </div>
                    <div>
                        <QuestionTypeSwitch
                            id="preview-type-switch"
                            onChange={setReferenceType}
                            selected={referenceType}
                        />
                    </div>
                </ReferenceType>
            </div>
            <Questions>
                {questionItems.length ? (
                    questionItems
                ) : (
                    <NoQuestionsForType>
                        <p>
                            {formatMessage(
                                {
                                    id: 'ReferenceRequest.questionsPreview.noQuestionsForTypeMsg',
                                },
                                {
                                    a: (email) => (
                                        <a
                                            href="mailto:support@refnow.co.uk?subject=Personal questions option"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            key="noquestiontypemsg"
                                        >
                                            {email}
                                        </a>
                                    ),
                                }
                            )}
                        </p>
                    </NoQuestionsForType>
                )}
            </Questions>
        </Container>
    );
};

export default QuestionsPreview;
