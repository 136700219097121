import styled from 'styled-components';

export const NAV_HEIGHT = '5em';
const MOBILE_BREAKPOINT = 950;

export const Container = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${NAV_HEIGHT};
    background-color: ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.common.white};
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    flex: 1;
    margin: 0 3%;

    > svg,
    img {
        width: 9em;
        height: 3em;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex: unset;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        justify-content: center;
        flex-direction: row-reverse;
        border-bottom: 1px solid ${(props) => props.theme.palette.grey[600]};
    }
`;

export const IconButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0px;
    padding: 0;
    justify-content: end;
    height: 100%;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        justify-content: center;
        padding: 0;

        > button {
            background-color: ${(props) => props.theme.palette.primary.main};
        }
    }
`;

export const NavListItem = styled.li`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    color: ${(props) => props.theme.palette.common.white};

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        min-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    :hover,
    &.active {
        > div {
            width: 100%;

            :after {
                content: '';
                width: 100%;
                border: 2px solid ${(props) => props.theme.palette.primary.main};
                position: absolute;
                bottom: 0px;
                display: flex;

                @media (max-width: ${MOBILE_BREAKPOINT}px) {
                    top: 22px;
                    border: none;
                }
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex: 1;
        max-width: unset;
        min-height: 40px;
        width: 100%;
        align-items: left;
        justify-content: left;
        color: ${(props) => props.theme.palette.primary.main};

        > div {
            justify-content: left;
            align-items: left;
            padding: 0.9em 2.5em;
        }
    }
`;

export const NavList = styled.ul`
    display: flex;
    flex: 1;
    list-style: none;
    height: 100%;
    gap: 3%;
    padding-left: 0;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: unset;
    }
`;

export const NavBar = styled.div`
    display: flex;
    height: 100%;
    flex: 5;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        height: auto;
        min-height: 50%;
        width: 100%;
        background-color: ${(props) => props.theme.palette.common.white};
        position: absolute;
        top: ${NAV_HEIGHT};
        flex-direction: column;
        align-items: center;
        transform: translateX(100%);
        z-index: 100;

        &.open {
            transition: transform 0.5s ease;
            transform: translateX(0%);
        }
    }
`;

export const DropdownList = styled.ul`
    display: none;
    position: absolute;
    top: 100%;
    min-width: 160px;
    width: max-content;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.palette.common.white};
    filter: drop-shadow(-6px 0 10px #312c5d24);
    z-index: 1000;
    border-radius: 10px;
    overflow: hidden;

    > li {
        padding: 0.9em 1.2em;
        cursor: pointer;
        color: ${(props) => props.theme.palette.secondary.main};

        :last-of-type {
            border-radius: 0 0 6px 6px;
        }

        :hover {
            > div {
                color: ${(props) => props.theme.palette.primary.main};
            }
        }
    }
`;

export const DropdownItem = styled(NavListItem)`
    position: relative;

    > div {
        color: ${(props) => props.theme.primaryDark};
        text-decoration: none;

        > svg {
            width: 24px;
            margin-left: 6px;
            transition: all 0.2s ease-in;

            path {
                :last-of-type {
                    stroke: white;
                }
            }
        }
    }

    :hover div > svg {
        transform: rotate(-180deg);
    }

    @media (min-width: ${MOBILE_BREAKPOINT}px) {
        :hover {
            > ${DropdownList} {
                display: block;
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        :hover,
        &.active {
            display: block;

            ${DropdownList} {
                background-color: ${(props) => props.theme.primaryDark};
                color: ${(props) => props.theme.primaryLight};
                display: block;
                position: relative;
                border-radius: 0;
                width: 100%;
            }
        }
    }
`;

export const DropdownIconItem = styled(DropdownItem)`
    justify-content: end;

    p {
        color: ${({ theme }) => theme.primaryDark};
    }

    > div {
        color: ${({ theme }) => theme.primaryDark};
        justify-content: end;
        text-decoration: none;
    }

    > div > svg {
        :first-of-type {
            margin-left: 0;
            transition: none;
        }
        :last-of-type {
            margin-left: 5px;
        }
    }

    :hover,
    &.active {
        > div :after {
            width: 50%;
            border: none;
            position: absolute;
            bottom: 0px;
            border-radius: 5px;
            display: flex;
            left: 25%;

            @media (max-width: ${MOBILE_BREAKPOINT}px) {
                top: 22px;
            }
        }

        > div {
            color: ${(props) => props.theme.secondary};
        }
    }

    :hover svg {
        :first-of-type {
            color: ${({ theme }) => theme.secondary};
        }

        transform: unset !important;
    }

    ul {
        left: calc(50% - 100px);
    }

    li {
        padding: 1em 1.5em;
    }
`;

export const ListItem = styled.li`
    color: ${({ active, theme }) => active && theme.primary};
    display: flex;
    gap: 10px;
    align-items: center;

    b {
        font-weight: 500;
    }
`;

export const LogoutItem = styled.li`
    /* border-top: 1px solid ${(props) => props.theme.secondaryLight}; */
`;

export const Burger = styled.div`
    display: none;
    cursor: pointer;

    > div {
        width: 25px;
        height: 3px;
        background-color: ${(props) => props.theme.palette.common.white};
        margin: 5px;
        transition: all 0.3s ease;

        ${({ open }) =>
            open === 'open' &&
            `
			:nth-of-type(1) {
				transform: rotate(-45deg) translate(-5px, 6px);
			}
			:nth-of-type(2) {
				opacity: 0;
			}
			:nth-of-type(3) {
				transform: rotate(45deg) translate(-5px, -6px);
			}
		`}
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: block;
    }
`;

export const AccountDetailsItem = styled.li`
    cursor: default !important;
    padding: 0.9em 1.5em !important;
`;

export const AccountDetails = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > div {
        :first-of-type {
            padding-right: 10px;

            > div {
                background-color: ${({ theme }) => theme.primaryDarkSubtle};
            }
        }

        :last-of-type {
            padding-left: 10px;

            > p {
                margin-bottom: 3px;

                :first-of-type {
                    font-weight: 700;
                }
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        justify-content: center;
    }
`;

export const NewFeature = styled.span`
    border-radius: 25px;
    padding: 0 8px;
    color: white;
    font-size: 0.9em;
    margin-left: 10px;
    background-color: ${(props) => props.theme.primary};
`;
