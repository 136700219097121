import { waitForElement } from './';

export default function (tour, formatMessage) {
    return [
        {
            id: 'request-dashboard',
            title: formatMessage({ id: 'Tours.viewRequest.requestDashboard.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.requestDashboard.description' }),
            canClickTarget: false,
            beforeShowPromise: () => waitForElement('candidates-sidebar'),
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'candidates-list',
            title: formatMessage({ id: 'Tours.viewRequest.candidatesList.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.candidatesList.description' }),
            attachTo: {
                element: '#cnd_1',
                on: 'right',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'candidate-search-sort',
            title: formatMessage({ id: 'Tours.viewRequest.candidateSearch.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.candidateSearch.description' }),
            attachTo: {
                element: '#candidates-search-sort-container',
                on: 'right',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        // {
        //     id: 'candidate-sidebar',
        //     title: formatMessage({ id: 'Tours.viewRequest.candidateSidebar.title' }),
        //     text: formatMessage({ id: 'Tours.viewRequest.candidateSidebar.description' }),
        //     attachTo: {
        //         element: '#candidate_verified_info_panel',
        //         on: 'left',
        //     },
        //     canClickTarget: false,
        //     beforeShowPromise: () => {
        //         const verifiedInfo = document.getElementById('candidate_verified_info');
        //         const btnContainer = document.getElementById('candidate-sidebar-btn-container');

        //         btnContainer.style.pointerEvents = 'none';
        //         verifiedInfo.click();

        //         return waitForElement('candidate_verified_info_panel', 200);
        //     },
        //     buttons: [
        //         {
        //             text: formatMessage({ id: 'Tours.nextStep' }),
        //             action: tour.next,
        //         },
        //     ],
        // },
        {
            id: 'candidate-sidebar-pdf-button',
            title: formatMessage({ id: 'Tours.viewRequest.candidateSidebarPDF.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.candidateSidebarPDF.description' }),
            attachTo: {
                element: '#candidate-sidebar-button-container',
                on: 'left',
            },
            canClickTarget: false,
            // beforeShowPromise: () => {
            //     const verifiedInfo = document.getElementById('candidate_verified_info');

            //     verifiedInfo.click();
            // },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'candidate-sidebar-references',
            title: formatMessage({ id: 'Tours.viewRequest.candidateSidebarReferences.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.candidateSidebarReferences.description' }),
            attachTo: {
                element: '#candidate-sidebar-references-accordion',
                on: 'left',
            },
            scrollTo: true,
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'request-timeline',
            title: formatMessage({ id: 'Tours.viewRequest.requestTimeline.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.requestTimeline.description' }),
            attachTo: {
                element: '#requests_timeline',
                on: 'left',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'request-created-timeline',
            title: formatMessage({ id: 'Tours.viewRequest.requestCreated.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.requestCreated.description' }),
            attachTo: {
                element: '#reference-timeline-request-created',
                on: 'left',
            },
            canClickTarget: false,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'request-actions',
            title: formatMessage({ id: 'Tours.viewRequest.requestCreatedActions.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.requestCreatedActions.description' }),
            attachTo: {
                element: '#reference-timeline-actions-container',
                on: 'left',
            },
            beforeShowPromise: () => {
                const el = document.getElementById('reference-timeline-actions-container');

                el.style.display = 'block';
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'request-delivery-log',
            title: formatMessage({ id: 'Tours.viewRequest.requestCreatedDeliveryLog.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.requestCreatedDeliveryLog.description' }),
            attachTo: {
                element: '#delivery_log_container',
                on: 'left',
            },
            beforeShowPromise: () => {
                const el = document.getElementById('reference-timeline-actions-container');

                el.style.display = 'none';

                const deliveryLog = document.getElementById('delivery_log_container');

                deliveryLog.style.display = 'block';
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'referee-added',
            title: formatMessage({ id: 'Tours.viewRequest.refereeAdded.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.refereeAdded.description' }),
            attachTo: {
                element: '#reference-timeline-referee-added',
                on: 'right',
            },
            beforeShowPromise: () => {
                const deliveryLog = document.getElementById('delivery_log_container');

                deliveryLog.style.display = 'none';
            },
            scrollTo: true,
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'reference-complete',
            title: formatMessage({ id: 'Tours.viewRequest.referenceComplete.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.referenceComplete.description' }),
            attachTo: {
                element: '#reference-timeline-reference-complete',
                on: 'left',
            },
            scrollTo: true,
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'reference-report',
            title: formatMessage({ id: 'Tours.viewRequest.referenceReport.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.referenceReport.description' }),
            scrollTo: true,
            canClickTarget: false,
            modalOverlayOpeningPadding: 10,
            beforeShowPromise: () => {
                const candidateReferencesAccordion = document.getElementById(
                    'candidate-sidebar-references-accordion'
                );

                candidateReferencesAccordion.childNodes[0].click();

                const referenceItems = document.querySelectorAll(
                    '#candidate-sidebar-reference-item'
                );

                referenceItems[1].childNodes[0].click();

                return waitForElement('main_section');
            },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'reference-report-verification',
            title: formatMessage({ id: 'Tours.viewRequest.referenceReportVerification.title' }),
            text: formatMessage({
                id: 'Tours.viewRequest.referenceReportVerification.description',
            }),
            attachTo: {
                element: '#verification',
                on: 'top',
            },
            scrollTo: true,
            canClickTarget: false,
            modalOverlayOpeningPadding: 5,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'reference-report-fraud-detection',
            title: formatMessage({ id: 'Tours.viewRequest.referenceReportFraud.title' }),
            text: formatMessage({ id: 'Tours.viewRequest.referenceReportFraud.description' }),
            attachTo: {
                element: '#fraud_detection',
                on: 'top',
            },
            scrollTo: true,
            canClickTarget: false,
            modalOverlayOpeningPadding: 5,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'finish',
            title: `${formatMessage({ id: 'Tours.viewRequest.finish.title' })} 🎉`,
            text: formatMessage({ id: 'Tours.viewRequest.finish.description' }),
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.finishStep' }),
                    action: tour.next,
                },
            ],
        },
    ];
}
