import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import RequiredYesIcon from '../../../assets/completed-icon-bw.svg';
import RequiredNoIcon from '../../../assets/required-no.svg';

const Container = styled.div`
    display: flex;
    box-shadow: -1px 0px 15px #1418640d;
    border-radius: 10px;
    margin: 10px;
    flex: 1 0 auto;

    p {
        margin: 0;
    }
`;

const Column1 = styled.div`
    display: flex;
    flex-direction: column;
    flex: 5;
    border-right: 2px solid ${(props) => props.theme.palette.grey[200]};
`;

const Title = styled.div`
    display: flex;
    border-bottom: 2px solid ${(props) => props.theme.palette.grey[200]};
    padding: 7px 20px;

    p {
        font-weight: 500;
    }

    > div {
        :first-of-type {
            flex-grow: 1;
            display: flex;
            align-items: center;
        }
    }
`;

const AnswerType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    > p {
        padding: 2px 18px;
        margin: 0;
        border-radius: 28px;
        border: 2px solid ${({ theme, type }) => theme.palette.questionTypes[type]};
        text-transform: capitalize;
        font-size: 14px;
    }
`;

const Question = styled.div`
    flex-direction: column;
    padding: 10px 20px;

    > p:first-of-type {
        font-style: italic;
    }

    p {
        margin: 0;
        margin-bottom: 10px;
    }
`;

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 5em;
`;

const RequiredTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid ${(props) => props.theme.palette.grey[200]};
    padding: 11px 20px;

    p {
        font-weight: 500;
        font-size: 14px;
    }
`;

const RequiredAnswer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px 20px;

    > svg {
        height: 20px;
        width: 20px;
    }
`;

const Answers = styled.div`
    margin-top: 1em;

    > p {
        margin: 0;
        font-weight: 500;
    }

    > div {
        display: flex;
        flex-wrap: wrap;
    }
`;

const Answer = styled.div`
    border-radius: 28px;
    background-color: ${(props) => props.theme.palette.grey[200]};
    margin-top: 0.8em;
    margin-right: 3px;
    padding: 5px 5px;
    font-size: 14px;
`;

export const QuestionItem = ({ questiontext, answertype, answeroptions, answerrequired }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <Column1>
                <Title>
                    <div>
                        <p>{formatMessage({ id: 'Questions.questionTitle' })}</p>
                    </div>
                    <AnswerType type={answertype}>
                        <p>{formatMessage({ id: `Questions.answerType.${answertype}` })}</p>
                    </AnswerType>
                </Title>
                <Question>
                    <p>{questiontext}</p>
                    {answeroptions && answeroptions.length && (
                        <Answers>
                            <p>{formatMessage({ id: 'Questions.answersTitle' })}</p>
                            <div>
                                {answeroptions.map(({ id, answer }) => (
                                    <Answer key={id}>
                                        <span>{answer}</span>
                                    </Answer>
                                ))}
                            </div>
                        </Answers>
                    )}
                </Question>
            </Column1>
            <Column2>
                <RequiredTitle>
                    <p>{formatMessage({ id: 'Questions.required' })}</p>
                </RequiredTitle>
                <RequiredAnswer>
                    {answerrequired === 1 ? <RequiredYesIcon /> : <RequiredNoIcon />}
                </RequiredAnswer>
            </Column2>
        </Container>
    );
};

export default QuestionItem;
