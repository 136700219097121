import routes from '../../../constants/routes';
import { waitForElement } from './';

export default function (tour, formatMessage) {
    return [
        {
            id: 'create-a-profile',
            title: formatMessage({ id: 'Tours.questionProfile.createProfile.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.createProfile.description' }),
            attachTo: {
                element: '#create-question-profile',
                on: 'top',
            },
            scrollTo: true,
            beforeShowPromise: () => waitForElement('create-question-profile'),
            modalOverlayOpeningRadius: 10,
            modalOverlayOpeningPadding: 10,
            advanceOn: { selector: '#create-question-profile', event: 'click' },
        },
        {
            id: 'add-a-profile',
            title: formatMessage({ id: 'Tours.questionProfile.addProfile.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.addProfile.description' }),
            attachTo: {
                element: '#add-profile-item-container',
                on: 'left',
            },
            highlightClass: 'highlight-class',
            beforeShowPromise: () => waitForElement('add-profile-item-container'),
            advanceOn: { selector: '#save-question-profile', event: 'click' },
            buttons: [
                {
                    text: 'Skip',
                    action: tour.next,
                },
            ],
        },
        {
            id: 'view-profile',
            title: formatMessage({ id: 'Tours.questionProfile.viewProfile.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.viewProfile.description' }),
            attachTo: {
                element: '#profiles_questions',
                on: 'left',
            },
            highlightClass: 'highlight-class',
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 2500);
                });
            },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'profile-reference-types',
            title: formatMessage({ id: 'Tours.questionProfile.referenceType.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.referenceType.description' }),
            attachTo: {
                element: '#custom-switch',
                on: 'right',
            },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'profile-question-locales',
            title: formatMessage({ id: 'Tours.questionProfile.localeSelector.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.localeSelector.description' }),
            attachTo: {
                element: '#question-profile-locale-switch-selector',
                on: 'left',
            },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'profile-add-question-1',
            title: formatMessage({ id: 'Tours.questionProfile.addQuestion1.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.addQuestion1.description' }),
            attachTo: {
                element: '#add-new-question',
                on: 'left',
            },
            advanceOn: { selector: '#add-new-question', event: 'click' },
            modalOverlayOpeningPadding: 10,
        },
        {
            id: 'profile-add-question-2',
            title: formatMessage({ id: 'Tours.questionProfile.addQuestion2.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.addQuestion2.description' }),
            attachTo: {
                element: '#new-question-item',
                on: 'top',
            },
            highlightClass: 'highlight-class',
            beforeShowPromise: () => waitForElement('new-question-item'),
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: () => {
                        const questionText = document.getElementById('questionText');

                        if (!questionText.value) {
                            return alert(
                                formatMessage({ id: 'Tours.questionProfile.addQuestion2.alert' })
                            );
                        } else {
                            tour.next();
                        }
                    },
                },
            ],
        },
        {
            id: 'profile-add-question-3',
            title: formatMessage({ id: 'Tours.questionProfile.addQuestion3.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.addQuestion3.description' }),
            attachTo: {
                element: '#required-question',
                on: 'left',
            },
            modalOverlayOpeningPadding: 10,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'profile-add-question-4',
            title: formatMessage({ id: 'Tours.questionProfile.addQuestion4.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.addQuestion4.description' }),
            attachTo: {
                element: '#addQuestionBtn',
                on: 'left',
            },
            advanceOn: { selector: '#addQuestionBtn', event: 'click' },
            modalOverlayOpeningPadding: 10,
        },
        {
            id: 'profile-question-list',
            title: formatMessage({ id: 'Tours.questionProfile.questionList.title' }),
            text: formatMessage({ id: 'Tours.questionProfile.questionList.description' }),
            attachTo: {
                element: '#questions_list',
                on: 'top',
            },
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        const newQuestionsList = document.getElementById('questions_list');

                        if (newQuestionsList && newQuestionsList.childNodes?.length > 0) {
                            setTimeout(() => {
                                clearInterval(interval);
                                resolve();
                            }, 3000);
                        }
                    }, 2000);
                });
            },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'finish-question-profile',
            title: `${formatMessage({ id: 'Tours.questionProfile.finish.title' })} 🎉`,
            text: formatMessage({ id: 'Tours.questionProfile.finish.description' }),
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.finishStep' }),
                    action: () => {
                        tour.next();
                        window.location.assign(routes.accountSetup);
                    },
                },
            ],
        },
    ];
}
