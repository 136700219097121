import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../constants/routes';
import SuccessReqCreatedIcon from '../../../../assets/successful-req-created-icon.svg';
import party from 'party-js';
import { track } from '../../../utils/analytics';

import { Button, Box, Typography } from '@mui/material';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    animation: ${fadeIn} 1s;
    overflow: auto;
    align-items: center;
    justify-content: center;

    svg {
        height: 120px;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 2em;
    margin-bottom: 1em;

    > button:first-child {
        margin-right: 15px;
    }
`;

export const SuccessMessage = ({ onClose, startNewRefRequest }) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const handleClose = (e) => {
        track('Back to request timeline');
        onClose(e);
        navigate(Routes.employer);
    };

    const handleAnotherRequest = () => {
        track('Create another request');
        startNewRefRequest();
    };

    useEffect(() => {
        party.confetti(document.getElementById('success-icon'), {
            count: party.variation.range(20, 40),
        });
    }, []);

    return (
        <Container>
            <Box id="success-icon">
                <SuccessReqCreatedIcon />
            </Box>
            <Box sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                    {formatMessage({ id: 'ReferenceRequest.labels.newRequestCreated' })}
                </Typography>
                <Typography variant="body1">
                    {formatMessage({ id: 'ReferenceRequest.successInfoText' })}
                </Typography>
                <Typography variant="body1">
                    {formatMessage({ id: 'ReferenceRequest.trackProgressMsg' })}
                </Typography>
            </Box>
            <ButtonContainer>
                <Button variant="outlined" color="secondary" size="large" onClick={handleClose}>
                    {formatMessage({ id: 'ReferenceRequest.linkToTimeline' })}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleAnotherRequest}
                >
                    {formatMessage({ id: 'ReferenceRequest.createAnotherRequest' })}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default SuccessMessage;
