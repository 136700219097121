import { waitForElement } from './';

export default function (tour, formatMessage) {
    return [
        {
            id: 'welcome',
            title: formatMessage({ id: 'Tours.welcome.intro.title' }),
            text: formatMessage({ id: 'Tours.welcome.intro.description' }),
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'welcome-getting-started',
            title: formatMessage({ id: 'Tours.welcome.gettingStarted.title' }),
            text: formatMessage({ id: 'Tours.welcome.gettingStarted.description' }),
            attachTo: {
                element: '#getting-started-tours-container',
                on: 'top',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'welcome-navigation-menu',
            title: formatMessage({ id: 'Tours.welcome.navigation.title' }),
            text: formatMessage({ id: 'Tours.welcome.navigation.description' }),
            attachTo: {
                element: '#navigation',
                on: 'bottom',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'welcome-account-menu-icon',
            title: formatMessage({ id: 'Tours.welcome.accountIcon.title' }),
            text: formatMessage({ id: 'Tours.welcome.accountIcon.description' }),
            attachTo: {
                element: '#navigation-account',
                on: 'left',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'welcome-account-menu',
            title: formatMessage({ id: 'Tours.welcome.accountMenu.title' }),
            text: formatMessage({ id: 'Tours.welcome.accountMenu.description' }),
            attachTo: {
                element: '#account-menu',
                on: 'left',
            },
            canClickTarget: false,
            highlightClass: 'highlight-class',
            beforeShowPromise: () => {
                const el = document.getElementById('navigation-account');

                el.click();

                return waitForElement('account-menu', 300);
            },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'welcome-account-setup',
            title: formatMessage({ id: 'Tours.welcome.accountSetup.title' }),
            text: formatMessage({ id: 'Tours.welcome.accountSetup.description' }),
            attachTo: {
                element: '#account-setup-button',
                on: 'left',
            },
            modalOverlayOpeningRadius: 10,
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'welcome-thank-you',
            title: formatMessage({ id: 'Tours.welcome.finish.title' }),
            text: `${formatMessage({ id: 'Tours.welcome.finish.description' })} 😊`,
            canClickTarget: false,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.finishStep' }),
                    action: tour.next,
                },
            ],
        },
    ];
}
