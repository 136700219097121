import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { track } from '../../../utils/analytics';

import { Button, TextField, MenuItem } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1.5em;
    height: 100%;
    overflow: auto;

    > div {
        :first-of-type {
            > h1 {
                font-size: 32px;
                margin-top: 0;
                text-transform: capitalize;
            }
        }

        :nth-of-type(2) {
            margin-bottom: 10px;
            height: 42vh;
        }
    }
`;

const InformationMessage = styled.div`
    padding: 0.8em 0;

    p {
        :last-of-type {
            margin: 1em 0 0;
            font-size: 0.9em;
            font-style: italic;
            font-weight: 500;
        }
    }
`;

const Options = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    gap: 1em;
`;

const OptionContainer = styled.div`
    flex-basis: 40%;
    margin: 1em 3em;
    margin-left: 0;

    @media (max-width: 768px) {
        flex-basis: 100%;
        margin: 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

const handleDisabledReminders = (reminders) => {
    let disabled = false;
    const disabledReminders = Object.entries(reminders).reduce((acc, [key, value]) => {
        const disabledAcc = {
            ...acc,
            [key]: disabled,
        };

        if (!disabled) {
            disabled = !value;
        }

        return disabledAcc;
    }, {});

    return disabledReminders;
};

export const RemindersPreview = ({ defaultReminderOptions = {}, setReminders, onClose }) => {
    const { formatMessage } = useIntl();
    const [reminderOptions, setReminderOptions] = useState(defaultReminderOptions);
    const [disabledReminderOptions, setDisabledReminderOptions] = useState(
        handleDisabledReminders(defaultReminderOptions)
    );
    const isReminderDisabled = (reminder) => disabledReminderOptions[reminder];
    const handleUpdate = (value, option) => {
        const updatedReminders = {
            ...reminderOptions,
            [option]: value,
        };

        setReminderOptions(updatedReminders);

        const updatedDisabledReminders = handleDisabledReminders(updatedReminders);

        setDisabledReminderOptions(updatedDisabledReminders);
    };
    const handleSave = () => {
        track('Default reminders updated', {
            updated: reminderOptions === defaultReminderOptions,
            reminderOptions,
        });
        setReminders(reminderOptions);
        onClose();
    };
    const getOptions = (showText = true) => {
        const afterPrevious = showText
            ? formatMessage({ id: 'ReferenceRequest.reminders.afterPrevious' })
            : '';

        return [
            {
                name: `${formatMessage({ id: 'ReferenceRequest.reminders.none' })}`,
                value: 0,
            },
            {
                name: `${formatMessage(
                    { id: 'ReferenceRequest.reminders.hourOption' },
                    {
                        hour: 3,
                    }
                )} ${afterPrevious}`,
                value: 3,
            },
            {
                name: `${formatMessage(
                    { id: 'ReferenceRequest.reminders.hourOption' },
                    {
                        hour: 6,
                    }
                )} ${afterPrevious}`,
                value: 6,
            },
            {
                name: `${formatMessage(
                    { id: 'ReferenceRequest.reminders.hourOption' },
                    {
                        hour: 12,
                    }
                )} ${afterPrevious}`,
                value: 12,
            },
            {
                name: `${formatMessage(
                    { id: 'ReferenceRequest.reminders.dayOption' },
                    {
                        day: 1,
                    }
                )} ${afterPrevious}`,
                value: 24,
            },
            {
                name: `${formatMessage(
                    { id: 'ReferenceRequest.reminders.dayOption' },
                    {
                        day: 2,
                    }
                )} ${afterPrevious}`,
                value: 48,
            },
            {
                name: `${formatMessage(
                    { id: 'ReferenceRequest.reminders.dayOption' },
                    {
                        day: 3,
                    }
                )} ${afterPrevious}`,
                value: 72,
            },
        ];
    };

    const getDefaultOption = (value, showAfterText = true) =>
        getOptions(showAfterText).find((o) => o.value === defaultReminderOptions[value]);

    return (
        <Container id="reminders-preview">
            <div>
                <h1>{formatMessage({ id: 'ReferenceRequest.reminders.title' })}</h1>
                <InformationMessage>
                    <p>{formatMessage({ id: 'ReferenceRequest.reminders.infoMsg1' })}</p>
                    <p>{formatMessage({ id: 'ReferenceRequest.reminders.infoMsg2' })}</p>
                    <p>{formatMessage({ id: 'ReferenceRequest.reminders.infoMsg3' })}</p>
                </InformationMessage>
            </div>
            <div>
                <Options id="reminders-preview-options">
                    <OptionContainer>
                        <TextField
                            select
                            fullWidth
                            label={formatMessage({
                                id: 'ReferenceRequest.reminders.labels.reminder1',
                            })}
                            disabled={isReminderDisabled(1)}
                            defaultValue={getDefaultOption(1, false).value}
                            variant="filled"
                            SelectProps={{
                                MenuProps,
                            }}
                            onChange={({ target }) => handleUpdate(target.value, 1)}
                        >
                            {getOptions(false).map(({ value, name }) => (
                                <MenuItem key={name} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </OptionContainer>
                    <OptionContainer>
                        <TextField
                            select
                            fullWidth
                            label={formatMessage({
                                id: 'ReferenceRequest.reminders.labels.reminder2',
                            })}
                            disabled={isReminderDisabled(2)}
                            defaultValue={getDefaultOption(2).value}
                            variant="filled"
                            SelectProps={{
                                MenuProps,
                            }}
                            onChange={({ target }) => handleUpdate(target.value, 2)}
                        >
                            {getOptions().map(({ value, name }) => (
                                <MenuItem key={name} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </OptionContainer>
                    <OptionContainer>
                        <TextField
                            select
                            fullWidth
                            label={formatMessage({
                                id: 'ReferenceRequest.reminders.labels.reminder3',
                            })}
                            disabled={isReminderDisabled(3)}
                            defaultValue={getDefaultOption(3).value}
                            variant="filled"
                            SelectProps={{
                                MenuProps,
                            }}
                            onChange={({ target }) => handleUpdate(target.value, 3)}
                        >
                            {getOptions().map(({ value, name }) => (
                                <MenuItem key={name} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </OptionContainer>
                    <OptionContainer>
                        <TextField
                            select
                            fullWidth
                            label={formatMessage({
                                id: 'ReferenceRequest.reminders.labels.reminder4',
                            })}
                            disabled={isReminderDisabled(4)}
                            defaultValue={getDefaultOption(4).value}
                            variant="filled"
                            SelectProps={{
                                MenuProps,
                            }}
                            onChange={({ target }) => handleUpdate(target.value, 4)}
                        >
                            {getOptions().map(({ value, name }) => (
                                <MenuItem key={name} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </OptionContainer>
                </Options>
            </div>
            <ButtonContainer>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleSave}
                    id="reminders-preview-save"
                >
                    {formatMessage({ id: 'ReferenceRequest.reminders.labels.save' })}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default RemindersPreview;
