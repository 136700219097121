import { createGlobalStyle } from 'styled-components';
import { palette } from './mui-theme';

import FormularLight from '../../assets/fonts/formular-light.otf';
import FormularLightItalic from '../../assets/fonts/formular-lightitalic.otf';
import FormularRegular from '../../assets/fonts/formular-regular.otf';
import FormularRegularItalic from '../../assets/fonts/formular-regularitalic.otf';
import FormularMedium from '../../assets/fonts/formular-medium.otf';
import FormularBold from '../../assets/fonts/formular-bold.otf';
import FormularBoldItalic from '../../assets/fonts/formular-bolditalic.otf';

export const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: formular;
		src: url(${FormularLight}) format('opentype');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: formular;
		src: url(${FormularLightItalic}) format('opentype');
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: formular;
		src: url(${FormularRegular}) format('opentype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: formular;
		src: url(${FormularRegularItalic}) format('opentype');
		font-weight: 400;
		font-style: italic;
	}
	@font-face {
		font-family: formular;
		src: url(${FormularMedium}) format('opentype');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: formular;
		src: url(${FormularBold}) format('opentype');
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: formular;
		src: url(${FormularBoldItalic}) format('opentype');
		font-weight: 700;
		font-style: italic;
	}

	* {
		box-sizing: border-box;
	}

	html, body {
		height: 100%;;
		font-size: 16px;
		font-family: formular, -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif;
		font-weight: 400;
		color: #020034;
	}

	body {
		background: white;
    	overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}

	label {
		margin-bottom: 0;
		font-weight: unset;
	}

	th {
		font-weight: unset;
	}

	b {
		font-weight: 500;
	}

	a {
		color: ${palette.primary.main};
	}

	a:hover {
		color: ${palette.secondary.main};
	}

	button {
		position: relative;
		border: none;
		border-radius: 10px;
		cursor: pointer;

		:focus {
            outline: none;
		}

		/* &:disabled, &:disabled:hover {
			border: 1px solid ${(props) => props.theme.primaryBorder} !important;
			background-color: #dcdcdc !important;
			color: #666666 !important;
		} */

		&:disabled {
			cursor: not-allowed;
		}
	}

	a.primary {
		position: relative;
		border: none;
		border-radius: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	button.cta, a.cta {
		padding: 10px 24px;
	}

	button.primary, a.primary {
		background-color: ${(props) => props.theme.primary};
		color: ${(props) => props.theme.primaryLight};
		border: 2px solid #ffffff00;

		:hover {
			background-color: ${(props) => props.theme.primaryHover};
		}

		/* :focus {
			outline: none;
			box-shadow: 0 0 0 3px ${(props) => `${props.theme.primaryHover}66`};
		} */
	}

	button.secondary {
		background-color: #ffffff00;
		border: 2px solid ${(props) => props.theme.primary};
		color: ${(props) => props.theme.primary};

		:hover {
            background-color: ${(props) => props.theme.primaryNeutral};
        }

        /* :focus {
            outline: none;
            box-shadow: 0 0 0 3px ${(props) => `${props.theme.primaryNeutral}66`};
        } */
	}

	button.tertiary {
		background-color: ${(props) => props.theme.primaryDark};
		border: 2px solid ${(props) => props.theme.primaryDarkBorder};
		color: ${(props) => props.theme.primaryLight};
		
		:hover {
			background-color: ${(props) => props.theme.primaryDarkSubtle};
        }

        :focus {
            outline: none;
            box-shadow: 0 0 0 3px ${(props) => `${props.theme.primaryHover}66`};
        }
	}

	//----------Shepherd Tour Styles----------
	button.shepherd-button {
		position: relative;
		border: none;
		border-radius: 10px;
		cursor: pointer;
		font-family: formular, sans-serif;

		:focus {
			outline: none;
		}
	}
	button.shepherd-button {
		background-color: #030037 !important;

		:hover {
			background: #8E2DE2 !important;  /* fallback for old browsers */
			background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2) !important;  /* Chrome 10-25, Safari 5.1-6 */
			background: linear-gradient(to right, #4A00E0, #8E2DE2) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
		}
	}
	button.shepherd-button-secondary {
		:hover {
			background-color: ${(props) => props.theme.primaryNeutral} !important;
		}
	}

	.shepherd-text {
		font-family: formular;
	}

	.shepherd-element {
		border-radius: 10px;
		/* border: 3px solid rgb(5, 32, 61); */
		filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 3px 10.5px) drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1.5px);
		/* border: 3px solid #d9d9d9; */
	}
	
	.shepherd-progress { 
		font-weight: 500;
		border-radius: 25px;
		color: #ffffff;
		padding: 0 15px;
		background: #8E2DE2;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	.shepherd-cancel-icon {
		margin-left: 10px;
	}

	.iti__country-list {
		outline: none;
		z-index: 1000;
		list-style: none;
		position: absolute;
		padding: 0;
		margin: 10px 0 10px -1px;
		box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
		background-color: white;
		width: 300px;
		max-height: 50vh;
		overflow-y: scroll;
		overflow-x: hidden;
		border-radius: 7px;

		.flag {
			display: inline-block;
			position: absolute;
			left: 13px;
			top: 8px;
		}
		.divider {
			padding-bottom: 5px;
			margin-bottom: 5px;
			border-bottom: 1px solid #ccc;
		}
		.country {
			position: relative;
			padding: 12px 9px 13px 46px;
			.dial-code {
				color: #6b6b6b;
			}
			&:hover {
				background-color: #f1f1f1;
			}
			&.highlight {
				background-color: #f1f1f1;
			}
		}
		.flag {
			margin-right: 7px;
			margin-top: 2px;
		}
		.country-name {
			margin-right: 6px;
		}
		.search {
			z-index: 2;
			position: sticky;
			top: 0;
			background-color: #fff;
			padding: 10px 0 6px 10px;
		}
		.search-emoji {
			display: none;
			font-size: 15px;
		}
		.search-box {
			border: 1px solid #cacaca;
			border-radius: 3px;
			font-size: 15px;
			line-height: 15px;
			margin-left: 6px;
			padding: 3px 8px 5px;
			outline: none;
			
			&:hover {
				border-color: #505050;
			}
		}

		.no-entries-message {
			padding: 7px 10px 11px;
			opacity: .7;
		}
	}
`;
