import { waitForElement } from './';

export default function (tour, formatMessage) {
    return [
        {
            id: 'new-request',
            title: formatMessage({ id: 'Tours.newRequest.startRequest.title' }),
            text: formatMessage({ id: 'Tours.newRequest.startRequest.description' }),
            attachTo: {
                element: '#navigation-new-reference-request',
                on: 'bottom',
            },
            modalOverlayOpeningRadius: 10,
            advanceOn: { selector: '#navigation-new-reference-request', event: 'click' },
            // when: {
            //     show: () => {
            //         document.addEventListener('click', handler, true);

            //         function handler(e) {
            //             if (e.target.id != 'navigation-new-reference-request') {
            //                 e.stopPropagation();
            //                 e.preventDefault();
            //             }
            //         }
            //     },
            // },
        },
        {
            id: 'candidate-details',
            title: formatMessage({ id: 'Tours.newRequest.candidateDetails.title' }),
            text: formatMessage({ id: 'Tours.newRequest.candidateDetails.description' }),
            attachTo: {
                element: '#candidate-details',
                on: 'bottom',
            },
            modalOverlayOpeningPadding: 10,
            beforeShowPromise: () => waitForElement('candidate-details'),
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'request-settings',
            title: formatMessage({ id: 'Tours.newRequest.requestSettings.title' }),
            text: formatMessage({ id: 'Tours.newRequest.requestSettings.description' }),
            attachTo: {
                element: '#new-request-form-container',
                on: 'left-end',
            },
            highlightClass: 'highlight-class',
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'reminders',
            title: formatMessage({ id: 'Tours.newRequest.reminders.title' }),
            text: formatMessage({ id: 'Tours.newRequest.reminders.description' }),
            attachTo: {
                element: '#request-reminders',
                on: 'left',
            },
            canClickTarget: false,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'digital-id-checks',
            title: formatMessage({ id: 'Tours.newRequest.digitalIdChecks.title' }),
            text: formatMessage({ id: 'Tours.newRequest.digitalIdChecks.description' }),
            attachTo: {
                element: '#candidate-right-to-work-checkbox',
                on: 'top',
            },
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.nextStep' }),
                    action: tour.next,
                },
            ],
        },
        {
            id: 'finish-request',
            title: `${formatMessage({ id: 'Tours.newRequest.finish.title' })} 🎉`,
            text: formatMessage({ id: 'Tours.newRequest.finish.description' }),
            attachTo: {
                element: '#send-request-btn',
                on: 'left-start',
            },
            canClickTarget: false,
            modalOverlayOpeningRadius: 10,
            advanceOn: { selector: '#send-request-btn', event: 'click' },
            buttons: [
                {
                    text: formatMessage({ id: 'Tours.finishStep' }),
                    action: tour.next,
                },
            ],
        },
    ];
}
