import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { track } from '../../utils/analytics';

import { getCandidates } from '../../actions/candidateActions';
import { postLogout } from '../../actions/userActions';
import Routes from '../../constants/routes.js';

import Arrow from '../../../assets/arrow.svg';
import BrandLogo from '../../../assets/refnow-logo-white.svg';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    ListItemText,
    Typography,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    useTheme,
} from '@mui/material';
import {
    ArrowForward,
    HelpOutlineOutlined,
    AddCircleOutlineOutlined,
    SettingsOutlined,
    LayersOutlined,
    LogoutOutlined,
    TourOutlined,
    CampaignOutlined,
} from '@mui/icons-material';
import { stringAvatar } from '../../utils/formatter';

import * as Nav from './styled';

const liveHidden = true;

export const Navigation = ({
    getCandidates,
    user,
    postLogout,
    newRefRequestBtnClick,
    closeRefRequestPanel,
}) => {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('employer');
    const [loadCustomUrl, setLoadCustomUrl] = useState(false);
    const navigate = useNavigate();
    const { organisation, creditBal, paidRTWCreditBal, fullname, isAdmin, isSuperAdmin } = user;
    const showAdminItems = isAdmin || isSuperAdmin;
    const theme = useTheme();

    const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
    const accountOpen = Boolean(accountAnchorEl);
    const handleAccountClick = (event) => {
        track('Opened Account menu');
        setAccountAnchorEl(event.currentTarget);
    };
    const handleAccountClose = (event, reason) => {
        if (window.TOUR_ACTIVE && reason === 'backdropClick') {
            return null;
        } else {
            setAccountAnchorEl(null);
        }
    };

    const getActiveClass = (tab) => (tab === activeTab ? 'active' : '');
    const handleReferenceRequest = () => {
        open && setOpen(false);
        track('New Request Open');
        newRefRequestBtnClick();
    };

    useEffect(() => {
        const hostname = window.location.hostname;
        const refnowUrls = [
            'app.refnow.co',
            'app.refnow.com',
            'localhost',
            'cryptic-silkworm.refnow.co',
        ];

        setLoadCustomUrl(!refnowUrls.includes(hostname));

        if (window.Canny) {
            window.Canny('initChangelog', {
                appID: '6406b1316f483a21ea656317',
                position: 'bottom',
                align: 'right',
            });
        }
    }, []);

    useEffect(() => {
        Object.entries(Routes).find(([key, value]) => {
            if (location.pathname == value) {
                setActiveTab(key);

                return true;
            }
        });
    }, [location.pathname]);

    return (
        <Nav.Container id="navigation">
            <Nav.LogoContainer
                id="navigation-logo"
                onClick={() => {
                    setActiveTab('employer');
                    navigate(Routes.employer);
                }}
            >
                {loadCustomUrl ? (
                    <img
                        src={`https://assets.refnow.co/app/custom/logo/${window.location.hostname}.png`}
                        onClick={() => {
                            getCandidates();
                            closeRefRequestPanel();
                        }}
                    />
                ) : (
                    <BrandLogo
                        onClick={() => {
                            getCandidates();
                            closeRefRequestPanel();
                        }}
                    />
                )}
            </Nav.LogoContainer>
            <Nav.NavBar className={classnames({ open })}>
                <Nav.NavList>
                    <Nav.DropdownItem
                        id="navigation-referencing"
                        title="Referencing"
                        className={
                            getActiveClass('employer') || getActiveClass('adminQuestionProfiles')
                        }
                        onClick={() => {
                            closeRefRequestPanel();
                            return true;
                        }}
                    >
                        <div>
                            {formatMessage({ id: 'Header.referencing.title' })}
                            <Arrow />
                        </div>
                        <Nav.DropdownList>
                            <Nav.ListItem
                                id="navigation-requests"
                                onClick={() => {
                                    setActiveTab('employer');
                                    getCandidates();
                                    navigate(Routes.employer);
                                    closeRefRequestPanel();
                                }}
                            >
                                <div>{formatMessage({ id: 'Header.referencing.requests' })}</div>
                            </Nav.ListItem>
                            <Nav.ListItem
                                id="navigation-referencing-dashboard"
                                onClick={() => {
                                    setActiveTab('employer');
                                    track('Navigate to Referencing Dashboard');
                                    navigate(Routes.employerDashboard);
                                }}
                            >
                                <div>{formatMessage({ id: 'Header.referencing.dashboard' })}</div>
                                {/* <Chip
                                    size="small"
                                    label={formatMessage({ id: 'Site.newFeature' })}
                                    color="primary"
                                    variant="contained"
                                    sx={{ color: 'white !important' }}
                                /> */}
                            </Nav.ListItem>
                            {showAdminItems && (
                                <Nav.ListItem
                                    id="navigation-questions"
                                    onClick={() => {
                                        setActiveTab('adminQuestionProfiles');
                                        navigate(Routes.adminQuestionProfiles);
                                    }}
                                >
                                    <div>
                                        {formatMessage({
                                            id: 'Header.referencing.questionProfiles',
                                        })}
                                    </div>
                                </Nav.ListItem>
                            )}
                            <Nav.ListItem
                                id="navigation-reporting"
                                onClick={() => {
                                    track('Navigate to Reporting page');
                                    window.open(
                                        'https://api.refnow.co/v1/reporting/html-reports/candidates',
                                        '_blank',
                                        ''
                                    );
                                }}
                            >
                                <div>{formatMessage({ id: 'Header.referencing.reporting' })}</div>
                            </Nav.ListItem>
                        </Nav.DropdownList>
                    </Nav.DropdownItem>
                    <Nav.DropdownItem
                        id="navigation-inbound"
                        title="Inbound"
                        className={getActiveClass('inbound')}
                        onClick={() => {
                            closeRefRequestPanel();
                            return true;
                        }}
                    >
                        <div>
                            {formatMessage({ id: 'Header.inbound.title' })}
                            <Arrow />
                        </div>
                        <Nav.DropdownList>
                            <Nav.ListItem
                                id="navigation-inbound-dashboard"
                                onClick={() => {
                                    setActiveTab('inbound');
                                    track('Navigate to Inbound Dashboard');
                                    navigate(Routes.inbound);
                                }}
                            >
                                <div>{formatMessage({ id: 'Header.inbound.dashboard' })}</div>
                                {/* <Chip
                                    size="small"
                                    label={formatMessage({ id: 'Site.newFeature' })}
                                    color="primary"
                                    variant="contained"
                                    sx={{ color: 'white !important' }}
                                /> */}
                            </Nav.ListItem>
                            {showAdminItems && (
                                <Nav.ListItem
                                    id="navigation-inbound-manager"
                                    onClick={() => {
                                        track('Navigate to Inbound Manager page');
                                        return window.open(
                                            'https://api.refnow.co/v1/html/inbound/list',
                                            '_blank',
                                            ''
                                        );
                                    }}
                                >
                                    <div>{formatMessage({ id: 'Header.inbound.manager' })}</div>
                                </Nav.ListItem>
                            )}
                        </Nav.DropdownList>
                    </Nav.DropdownItem>
                    <Nav.DropdownItem
                        id="navigation-recruit"
                        title="Recruit"
                        className={getActiveClass('talentPool')}
                        onClick={() => {
                            closeRefRequestPanel();
                            return true;
                        }}
                    >
                        <div>
                            {formatMessage({ id: 'Header.recruit.title' })}
                            <Arrow />
                        </div>
                        <Nav.DropdownList>
                            <Nav.ListItem
                                id="navigation-talent-pool"
                                onClick={() => {
                                    setActiveTab('talentPool');
                                    track('Navigate to Talent Pool');
                                    navigate(Routes.talentPool);
                                }}
                            >
                                <div>{formatMessage({ id: 'Header.recruit.talentPool' })}</div>
                            </Nav.ListItem>
                        </Nav.DropdownList>
                    </Nav.DropdownItem>
                </Nav.NavList>
                <Nav.ButtonContainer>
                    <Nav.IconButtonsContainer>
                        <Tooltip title={formatMessage({ id: 'Header.announcements.title' })}>
                            <IconButton
                                size="medium"
                                data-canny-changelog
                                sx={{ ml: 1 }}
                                onClick={() => track('Opened Announcements menu')}
                            >
                                <CampaignOutlined
                                    sx={{
                                        color: theme.palette.common.white,
                                        '&:hover': { color: theme.palette.primary.main },
                                        fontSize: 32,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage({ id: 'Header.account.title' })}>
                            <IconButton
                                onClick={handleAccountClick}
                                size="medium"
                                id="navigation-account"
                                sx={{ ml: 1 }}
                                aria-controls={accountOpen ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={accountOpen ? 'true' : undefined}
                            >
                                <SettingsOutlined
                                    sx={{
                                        color: theme.palette.common.white,
                                        '&:hover': { color: theme.palette.primary.main },
                                        fontSize: 28,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={accountAnchorEl}
                            open={accountOpen}
                            onClose={handleAccountClose}
                            disablePortal
                            keepMounted
                            sx={{
                                li: {
                                    padding: '0.7em 1.5em',
                                },
                            }}
                            PaperProps={{
                                id: 'account-menu',
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(-6px 0 10px #312c5d24)',
                                    borderRadius: '10px',
                                    boxShadow: 'unset',
                                    mt: '15px',
                                    minWidth: '300px',
                                    '& .MuiAvatar-root': {
                                        width: 45,
                                        height: 45,
                                        ml: -0.5,
                                        mr: 2,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleAccountClose();
                                    navigate(Routes.settings.index);
                                }}
                            >
                                <ListItemIcon>
                                    <Avatar {...stringAvatar(fullname)} />
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography variant="subtitle1" noWrap>
                                            {organisation}
                                        </Typography>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Box>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {formatMessage(
                                                        { id: 'Header.account.creditsRemaining' },
                                                        {
                                                            b: (txt) => <b>{txt}</b>,
                                                            creditsBalance: creditBal,
                                                        }
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {formatMessage(
                                                        {
                                                            id: 'Header.account.digitalIdCreditsRemaining',
                                                        },
                                                        {
                                                            b: (txt) => <b>{txt}</b>,
                                                            creditsBalance: paidRTWCreditBal,
                                                        }
                                                    )}
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    }
                                />
                            </MenuItem>
                            {user?.tours?.progress !== 100 && (
                                <MenuItem
                                    id="account-setup-button"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        sx={{ width: '100%' }}
                                        onClick={() => {
                                            track('Clicked Account Setup');
                                            handleAccountClose();
                                            navigate(Routes.accountSetup);
                                        }}
                                        endIcon={
                                            user?.tours?.progress > 0 &&
                                            user?.tours?.progress < 100 ? (
                                                <ArrowForward />
                                            ) : null
                                        }
                                    >
                                        {user?.tours?.progress > 0
                                            ? formatMessage({
                                                  id: 'Header.account.continueSetup',
                                              })
                                            : formatMessage({
                                                  id: 'Header.account.startSetup',
                                              })}
                                    </Button>
                                </MenuItem>
                            )}
                            <Divider />
                            {isSuperAdmin && (
                                <MenuItem
                                    id="navigation-buyCredits"
                                    key="navigation-buyCredits"
                                    onClick={() => {
                                        handleAccountClose();
                                        track('Navigate to Buy Credits page [Account Menu]');
                                        navigate(Routes.settings.buyCredits);
                                    }}
                                >
                                    <ListItemIcon>
                                        <AddCircleOutlineOutlined fontSize="small" />
                                    </ListItemIcon>
                                    {formatMessage({ id: 'Header.admin.buyCredits' })}
                                </MenuItem>
                            )}

                            {isSuperAdmin && !liveHidden && (
                                <MenuItem
                                    id="navigation-subscription"
                                    key="navigation-subscription"
                                    onClick={() => {
                                        handleAccountClose();
                                        track('Navigate to Subscription page [Account Menu]');
                                        navigate(Routes.settings.subscription);
                                    }}
                                >
                                    <ListItemIcon>
                                        <LayersOutlined fontSize="small" />
                                    </ListItemIcon>
                                    {formatMessage({ id: 'Header.admin.subscription' })}
                                </MenuItem>
                            )}
                            {user?.tours?.progress === 100 && (
                                <MenuItem
                                    id="navigation-interactive-tours"
                                    onClick={() => {
                                        track('Clicked Interactive Tours');
                                        handleAccountClose();
                                        navigate(Routes.accountSetup);
                                    }}
                                >
                                    <ListItemIcon>
                                        <TourOutlined fontSize="small" />
                                    </ListItemIcon>
                                    {formatMessage({ id: 'Header.interactiveTours' })}
                                </MenuItem>
                            )}
                            <MenuItem
                                id="navigation-settings"
                                onClick={() => {
                                    track('Navigate to Settings [Account Menu]');
                                    handleAccountClose();
                                    navigate(Routes.settings.index);
                                }}
                            >
                                <ListItemIcon>
                                    <SettingsOutlined fontSize="small" />
                                </ListItemIcon>
                                {formatMessage({ id: 'Header.account.settings' })}
                            </MenuItem>
                            <MenuItem
                                id="navigation-help"
                                onClick={() => {
                                    track('Navigate to Help page [Account Menu]');
                                    return window.open('https://help.refnow.co', '_blank', '');
                                }}
                                title={formatMessage({ id: 'Header.helpdesk' })}
                            >
                                <ListItemIcon>
                                    <HelpOutlineOutlined fontSize="small" />
                                </ListItemIcon>
                                {formatMessage({ id: 'Header.help' })}
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                id="navigation-logout"
                                onClick={() => {
                                    handleAccountClose();
                                    postLogout();
                                }}
                            >
                                <ListItemIcon>
                                    <LogoutOutlined fontSize="small" />
                                </ListItemIcon>
                                {formatMessage({ id: 'Header.account.logout' })}
                            </MenuItem>
                        </Menu>
                    </Nav.IconButtonsContainer>
                    <Button
                        id="navigation-new-reference-request"
                        color="primary"
                        variant="contained"
                        size="large"
                        data-intercom-target="new_reference_request"
                        sx={{ margin: '10px' }}
                        onClick={handleReferenceRequest}
                    >
                        {formatMessage({ id: 'Header.requestButton' })}
                    </Button>
                </Nav.ButtonContainer>
            </Nav.NavBar>
            <Nav.Burger
                id="navigation-mobile-menu"
                open={open ? 'open' : ''}
                onClick={() => setOpen(!open)}
            >
                <div />
                <div />
                <div />
            </Nav.Burger>
        </Nav.Container>
    );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
    getCandidates,
    postLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
