import { createTheme } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 320,
            sm: 481,
            md: 641,
            lg: 961,
            xl: 1025,
            xxl: 1281,
            '2xl': 1536,
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#5A3FF5',
            100: '#ECEBFD',
            200: '#DED9FD',
            300: '#BBB3FA',
            400: '#836FF7',
        },
        secondary: {
            main: '#020034',
            100: '#DCDCFC',
            200: '#8888B8',
            300: '#5F5F97',
            400: '#4e4d71',
            background: '#F6F8FA',
        },
        tertiary: {
            main: '#75FBA8',
            100: '#E7FEF3',
            200: '#B5FDDA',
            300: '#9FFFD1',
            400: '#97FCBE',
        },
        accent: {
            main: '#FF7EAD',
            100: '#FFE5EF',
            200: '#FFBED6',
            300: '#FF9EC1',
            400: '#FF8BB5',
        },
        // error: {
        //     main: '#DE2301',
        //     100: '#ff8d8d',
        //     300: '#fc5b5b',
        // },
        // warning: {
        //     main: '#FF934A',
        //     50: '#ffeee2',
        //     100: '#FFDFC9',
        //     200: '#FFC9A5',
        //     300: '#FFAE78',
        //     400: '#FF9E5C',
        // },
        // info: {
        //     main: '#C0D6EF',
        //     100: '#F6F8FA',
        //     200: '#ECF3FA',
        //     300: '#D0E0F3',
        //     400: '#C6DAF1',
        // },
        grey: {
            50: '#F6F8FA',
            100: '#F7F6FF',
            200: '#EFEFF0',
            300: '#E4E4E6',
            350: '#DFE4E8',
            main: '#D4D4D6',
            600: '#a4a4a4',
            700: '#787878',
            contrastText: '#02003480',
        },
        success: {
            light: '#F6FBF3',
            main: '#008454',
            400: '#03D9A3',
        },
        questionTypes: {
            text: '#F86E7C',
            multitext: '#5729FF',
            dropdown: '#E3C6FD',
            number: '#5DD6BD',
        },
        text: {
            primary: '#020034',
            secondary: '#02003499',
            disabled: '#02003461',
        },
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            'formular',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
        ].join(','),
        body1: {
            fontWeight: 400,
            lineHeight: '1.2',
            fontSize: '0.9rem',
        },
        body2: {
            lineHeight: '1.2',
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: '1.1',
            letterSpacing: '-1px',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: ({ ownerState }) => ({
                    // Some CSS
                    textTransform: ownerState.uppercase ? 'uppercase' : 'none',
                    position: 'relative',
                    borderRadius: '10px',
                    boxShadow: 'none',
                }),
                outlined: {
                    backgroundColor: '#fff',
                    '&:hover': {
                        backgroundColor: '#F7F6FF',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: `${theme.spacing(1)} !important`,
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: `${theme.spacing(1)} !important`,
                }),
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '&.Mui-expanded': {
                        minHeight: '54px',
                    },
                },
                content: {
                    // padding: '8px 4px 16px',
                    '&.Mui-expanded': {
                        margin: '10px 0',
                        marginBottom: '6px',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    // padding: '0px 8px 16px',
                    paddingTop: 0,
                },
            },
        },
        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    fontWeight: 300,
                    backgroundColor: '#f5f6f7',
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontWeight: 300,
                }),
                notchedOutline: {
                    borderColor: '#E0E0E0',
                },
                // input: {
                //     padding: '12px',
                // },
            },
        },
        // MuiTextField: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: '#fff',
        //         },
        //     },
        // },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-filled': {
                        fontWeight: 300,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#020034',
                    color: '#fff',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: '36px',
                },
                // indicator: {
                //     display: 'none',
                // },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: '36px',
                    borderRadius: '6px',
                    padding: '0 16px',
                    textTransform: 'Capitalize',
                    // '&.Mui-selected': {
                    //     backgroundColor: '#EFEFF0',
                    //     color: '#020034',
                    // },
                },
            },
        },
    },
});

export const { palette } = theme;

export default theme;
